import React from 'react';

import truck from './truck.png'
const mainColor = '#4299e1';

const Road = () => (

  <img src={truck} alt="truck" />
);

export default Road;
