import React from 'react';
import ship from './ship_1.png'

const mainColor = '#4299e1';

const Ship = () => (

  <>
    <img src={ship} alt="Ship" />
  </>
);

export default Ship;
