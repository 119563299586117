import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import Road from '../svg/Road';
import Ship from '../svg/Ship';

import SvgCharts from '../svg/SvgCharts';


export default () => (
  <Layout>
    <section id="home" className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Ambeway Freight Forwarders
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Ambeway Freight Forwarders is an international logistics and freight forwarding company with Headquartered at Mumbai,
            India.<br />We offer competitive Ocean Freight rates , Air Freight rates to the  worldwide locations, Custom clearance,
            Road transportation and Rail transportation.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">See more</Button>
          </p>
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="about" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">What we provide</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl"> Custom Clearance</p>
              <p className="mt-4">
                We Provide customs clearance of import consignments both by sea and air efficiently. Our clearing agents, being some of the most experienced, will cater to your needs and strive for perfect execution. For more details please contact us from contact section below.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Import Services</p>
              <p className="mt-4">
                Our import services take the job out of bringing shipments into the country. <br />Conatct us and we’ll collect your package, make sure it clears customs and cover any import duty required. We offer wide range of Import services feel free to contact us and get the complete details.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              {/* <div className="sm:w-1/2">
                <Navigator />

              </div> */}
              <p className="font-semibold text-xl">Transportation Management</p>
              <p className="mt-4">
                We provide Transportation Management in road and rail logistics operations,we  offer optimum,reasonable,coordinated and competent transport solutions. Some of this services include Pickup of Export Cargo from shipper warehouse to nearest port.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section id="services" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Freight Services</h2>


        <SplitSection
          id="services"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Air Freight</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                AFF offers ,a cost effective,secure,timely and efficient solution for all air freight shipments, to and from any point in the world.
                Get your goods delivered at the right time, rather than “just in time.” Our air freight services can help you move  any cargo quickly to anywhere in the world.
          </p>
            </div>
          }
          secondarySlot={<SvgCharts />}
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Sea Freight
          </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                We deliver highly flexible services through our Contacts with leading shipping lines. Qur global sea freight specialists are ready to find the most efficient shipping connections to meet your requirements. It doesn't matter how big your business is, we will support your growth and expansion.
          </p>
            </div>
          }
          secondarySlot={<Ship />}
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Road Freight
          </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                The use of road transport in cargo delivery is the only way to ensure the  delivery of goods “from door to door”, as well as to remote geographic locations where there are no possibility to deliver cargoes. We have a great experience in the Road transportation, which provides a wide geography, reliability and favorable prices for our clients. We make sure your package reaches to you in time at most efficient cost.
          </p>
            </div>
          }
          secondarySlot={<Road />}
        />

      </div>
    </section>

    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="96%" secondaryText="On Time Delivery" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="99%" secondaryText="Error Free Shipment" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="100%" secondaryText="Satisfaction" />
          </div>
        </div>
      </div>
    </section>
    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <section id="contact">
      <div class="relative flex items-top justify-center min-h-screen bg-white dark:bg-gray-900 sm:items-center sm:pt-0">
        <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
          <div class="mt-8 overflow-hidden">
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div class="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
                <h1 class="text-4xl sm:text-5xl text-gray-800 dark:text-white font-extrabold tracking-tight">
                  Get in touch
                        </h1>
                <p class="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-gray-400 mt-2">
                  Fill in the form to get the quotation
                        </p>

                <div class="flex items-center mt-8 text-gray-600 dark:text-gray-400">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <div class="ml-4 text-md tracking-wide font-semibold w-40">
                    Malwani Chruch, Malad,<br /> Mumbai : 400095.
                            </div>
                </div>

                <div class="flex items-center mt-4 text-gray-600 dark:text-gray-400">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <div class="ml-4 text-md tracking-wide font-semibold w-40">
                    +91 877 950 7724
                            </div>
                </div>

                <div class="flex items-center mt-2 text-gray-600 dark:text-gray-400">
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <div class="ml-4 text-md tracking-wide font-semibold w-40">
                    info@ambewayforwarders.com
                            </div>
                </div>
              </div>

              <form class="p-6 flex flex-col justify-center" action="https://formspree.io/f/meqvojob"
                method="POST">
                <div class="flex flex-col">
                  <label for="name" class="hidden">Full Name</label>
                  <input required type="name" name="name" id="name" placeholder="Full Name" class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none" />
                </div>

                <div class="flex flex-col mt-2">
                  <label for="email" class="hidden">Email</label>
                  <input required type="email" name="email" id="email" placeholder="Email" class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none" />
                </div>

                <div class="flex flex-col mt-2">
                  <label for="tel" class="hidden">Number</label>
                  <input required type="tel" name="tel" id="tel" placeholder="Telephone Number" class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none" />
                </div>

                <div class="flex flex-col">
                  <label for="name" class="hidden">Message</label>
                  <textarea required type="name" name="name" id="name" placeholder="Your Message" class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-indigo-500 focus:outline-none" />
                </div>


                <button type="submit" class="md:w-32 bg-indigo-600 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-indigo-500 transition ease-in-out duration-300">
                  Submit
                        </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>


  </Layout>
);
